import React from "react";
import GunnyLogo from "../images/gunny-logo.png";
import Twitter from "../images/twitter.svg";
import YouTube from "../images/youtube.svg";
import Telegram from "./../images/telegram.svg";

export default class Header extends React.Component {
  render() {
    return (
      <>
        <header>
          <nav className="navbar navbar-expand-xl navbar-light p-xl-0">
            <div className="container-fluid container-xl px-xl-0">
              <a className="navbar-brand mx-0" href="#">
                <img src={GunnyLogo} alt="logo" className="img-fluid" />
              </a>
              <button
                className="navbar-toggler hamburger hamburger--emphatic"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#home"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#champions">
                      Champions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pets">
                      Pets
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#weapons">
                      Weapons
                    </a>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </header>
      </>
    );
  }
}
