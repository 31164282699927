import "./styles/globals.css";
import styles from "./styles/Home.module.css";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import postscribe from "postscribe";
import Header from "./components/header";
import Footer from "./components/footer";
import Team from "./components/team";
import ConnectIcon from "./images/connect-icon-1.svg";
import ConnectIcon2 from "./images/connect-icon-2.svg";
import ConnectIcon3 from "./images/connect-icon-3.svg";
import ConnectIcon4 from "./images/connect-icon-4.svg";
import ConnectIcon5 from "./images/connect-icon-5.svg";
import BannerText from "./images/banner-text.png";
import BannerCharacter from "./images/banner-character.png";
import JoinUsIcon from "./images/join-us-icon.svg";
import BookIcon from "./images/book-icon.png";
import Page1 from "./images/page-1.png";
import Page2 from "./images/page-2.png";
import Page3 from "./images/page-3.png";
import Page4 from "./images/page-4.png";
import Page5 from "./images/page-5.png";
import Page6 from "./images/page-6.png";
import Page7 from "./images/page-7.png";
import Page8 from "./images/page-8.png";
import Page9 from "./images/page-9.png";
import PrevIcon from "./images/prev-icon.png";
import NextIcon from "./images/next-icon.png";
import Mascota1 from "./images/mascota-1.png";
import Mascota2 from "./images/mascota-2.png";
import Mascota3 from "./images/mascota-3.png";
import Mascota4 from "./images/mascota-4.png";
import CoinIcon from "./images/coin-icon.png";
import DiamondIcon from "./images/diamond-icon.png";
import ModeVideo1 from "./videos/gamemodes-1.webm";
import ModeVideo2 from "./videos/gamemodes-2.webm";
import PetVideo1 from "./videos/pet-1.webm";
import Mailchimp from "./components/mailchimp";




export default function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    postscribe("#postscribe-script", "<script src='js/custom.js'></script><!-- Global site tag (gtag.js) - Google Analytics -->    <script async src='https://www.googletagmanager.com/gtag/js?id=UA-239269047-1'></script> <script> window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());      gtag('config', 'UA-239269047-1');</script> ");
    window.scrollTo(0, 0);
 document.body.scrollTop = 0;
 const href = window.location.href.substring(
   window.location.href.lastIndexOf('#') + 1,
 );
 if (window.location.href.lastIndexOf('#') > 0) {
   document.getElementById(href)?.scrollIntoView();
 }
  }, []);
  return (
    <div className={styles.container}>
      <div className="d-none">{JSON.stringify(process.env.PUBLIC_URL)}</div>
      {/***************/}
      {/* Header starts */}
      {/* ***************/}
      <Header />
      {/***************/}
      {/* Header Ends */}
      {/* ***************/}
      {/***************/}
      {/* Banner Section starts */}
      {/* ***************/}
      <section className="banner-section section-blur-effect" id="home">
        <div className="container-fluid container-xl">
          <div className="row">
            <div className="col-xl-6">
              <div className="banner-blk">
                <div>
                  <img
                    src={BannerText}
                    alt=""
                    className="banner-heading img-fluid"
                  />
                </div>
                <p className="banner-subtitle">
                Join the battle for your Kingdom. Pick your hero and battle as one of 5 factions in a world of arcane magic and supernatural Inugi. It’s completely free to play.
                </p>
                <ul className="list-inline">
                  <li className="list-inline-item mr-5">
                    <a href="#connect-section" className="btn btn-primary btn-primary2">
                      Join  the waiting list
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/***************/}
      {/* Banner Section Ends */}
      {/* ***************/}
      {/***************/}
      {/* Book Section Starts */}
      {/* ***************/}
      <section className="book-section section-blur-effect" id="champions">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 text-center" >
              <h2 className="main-title">The battle begins</h2>
              <p className="px-5">
              Choose your favorite faction, each one has different reasons to be in this war
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="flipbook-blk position-relative">
                <h2 className="text-center main-title--small book-title">
                  <img
                    src={BookIcon}
                    alt="book-icon"
                    className="img-fluid book-icon"
                  />
                  book of factions
                </h2>
                <div className="flipbook-content-blk position-relative">
                  <div className="wrapper">
                    <div className="aspect">
                      <div className="aspect-inner">
                        <div id="flipbook" className="flipbook">
                          <div className="page">
                            <img src={Page1} alt="" className="img-fluid" />
                          </div>
                          <div className="page">
                            <img src={Page2} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page3} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page4} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page5} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page6} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page7} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page8} alt="" className="img-fluid sepia" />
                          </div>
                          <div className="page">
                            <img src={Page9} alt="" className="img-fluid sepia" />
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flipbook-nav">
                  <div id="prevBtn">
                    <img src={PrevIcon} alt="icon" className="img-fluid" />
                  </div>
                  <div id="nextBtn">
                    <img src={NextIcon} alt="icon" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/***************/}
      {/* Book Section ends */}
      {/* ***************/}
      {/***************/}
      {/* Mascota Section Starts */}
      {/* ***************/}
      <section className="mascota-section section-blur-effect" id="pets">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 text-center">
              <h2 className="main-title">CHOOSE YOUR Inugi</h2>
              <p className="px-5">
                <strong>The Inugis”</strong>  are beings from arcane magic, an energy unknown to the inhabitants of Gunny until the arrival of these creatures.
              </p>
              <p className="px-5">
              Capable of channeling this energy, the Inigus have powers never before seen by humans, and can be used to turn the tide of war…
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="mascota-slider-blk">
                <div className="mascota-slider swiper">
                  <Swiper
                    modules={[Thumbs, EffectFade]}
                    effect={"fade"}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    loop={true}
                    allowTouchMove={false}
                    className="moscata-slider-main"
                  >
                    <SwiperSlide>
                      <div className="moscata-slider-main-img pet-video" >
                        <video
                          width="400"
                          autoPlay
                          loop
                          muted
                          className="img-fluid"
                        >
                          <source src={PetVideo1} type="video/webm" />
                        </video>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="moscata-slider-main-img">
                        <img src={Mascota2} alt="" className="img-fluid" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="moscata-slider-main-img">
                        <img src={Mascota3} alt="" className="img-fluid" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="moscata-slider-main-img">
                        <img src={Mascota4} alt="" className="img-fluid" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="swiper-thumb-blk">
                    <Swiper
                      modules={[Thumbs, Navigation]}
                      watchSlidesProgress
                      thumbs={{ swiper: thumbsSwiper }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 0,
                        },
                        992: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                      }}
                      loop={true}
                      centeredSlides={true}
                      slideToClickedSlide={true}
                      freeMode={true}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      className="moscata-slider-thumb"
                    >
                      <SwiperSlide>
                        <div className="moscata-slider-thumb-blk">
                          <div className="moscata-slider-thumb-img">
                            <img src={Mascota1} alt="" className="img-fluid" />
                          </div>
                          <div className="moscata-slider-thumb-text">
                            Roky
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="moscata-slider-thumb-blk">
                          <div className="moscata-slider-thumb-img">
                            <img src={Mascota2} alt="" className="img-fluid" />
                          </div>
                          <div className="moscata-slider-thumb-text">
                            Coming soon
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="moscata-slider-thumb-blk">
                          <div className="moscata-slider-thumb-img">
                            <img src={Mascota3} alt="" className="img-fluid" />
                          </div>
                          <div className="moscata-slider-thumb-text">
                            Coming soon
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="moscata-slider-thumb-blk">
                          <div className="moscata-slider-thumb-img">
                            <img src={Mascota4} alt="" className="img-fluid" />
                          </div>
                          <div className="moscata-slider-thumb-text">
                            Coming soon
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <div className="swiper-slider-nav">
                      <div ref={navigationPrevRef}>
                        <img src={PrevIcon} alt="icon" className="img-fluid" />
                      </div>
                      <div ref={navigationNextRef}>
                        <img src={NextIcon} alt="icon" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/***************/}
      {/* Mascota Section Ends */}
      {/* ***************/}
      {/***************/}
      {/* Dispara Section Start */}
      {/* ***************/}
      <section className="dispara-section section-blur-effect" id="weapons">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12">
              <div className="dispara-heading-blk d-flex justify-content-between align-items-center">
                <img src={CoinIcon} alt="icon" className="img-fluid" />
                <h2 className="main-title text-center mb-0">
                Choose your weapon
                </h2>
                <img src={DiamondIcon} alt="icon" className="img-fluid" />
              </div>
              <p className="px-lg-5 mx-lg-5 text-center">
                <strong>Wars are not won with ideas alone.</strong>
              </p>
              <p className="px-lg-5 mx-lg-5 text-center">
              Each faction creates and crafts their own magical weapons which gives them special powers.
              </p>
              
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 pt-5">
              <div className="lottie-animation-blk pt-4">
                <div id="gun-animation"></div>
                {/* Tooltip start */}
                <div
                  className="d-none anim-with-tooltip"
                  data-target="gunpart-4"
                >
                  Cannon
                </div>
                {/* Tooltip end */}
                {/* Tooltip start */}
                <div
                  className="d-none anim-with-tooltip"
                  data-target="gunpart-3"
                >
                  Ammunition clip
                </div>
                {/* Tooltip end */}
                {/* Tooltip start */}
                <div
                  className="d-none anim-with-tooltip"
                  data-target="gunpart-2"
                >
                  Gem
                </div>
                {/* Tooltip end */}
                {/* Tooltip start */}
                <div
                  className="d-none anim-with-tooltip"
                  data-target="gunpart-1"
                >
                  Butt
                </div>
                {/* Tooltip end */}
                <div className="text-center gunanimations-btn-blk">
                  <button className="btn btn-primary start-button btn-show">
                    OPEN IT!
                  </button>
                  <button className="btn btn-primary close-button btn-hide">
                    CLOSE IT!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/***************/}
      {/* Dispara Section End */}
      {/* ***************/}
      {/***************/}
      {/* Formas Section Start */}
      {/* ***************/}
      <section className="formas-section section-blur-effect">
        <div className="container-fluid container-lg">
        <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 text-center">
              <h2 className="main-title">Game Modes</h2>
              <p className="px-5">
              Compete for your Kingdom in two different game modes
              </p>

            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0 px-lg-5 text-center">
              <div className="formas-img-blk">
              <video
                          width="400"
                          autoPlay
                          loop
                          muted
                          className="img-fluid"
                        >
                          <source src={ModeVideo1} type="video/webm" />
                        </video>
              </div>
            </div>
            <div className="col-lg-6 text-center ">
              <h2 className="main-title">Deathmatch</h2>
              <p>
              2 different teams competing to see who is the last one standing.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            
            <div className="col-lg-6 text-center">
              <h2 className="main-title">Capture the flag</h2>
              <p>
              2 teams will compete to steal the opposing team's flag, whoever gets the most flags will win.
              </p>
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0 px-lg-5 text-center">
              <div className="formas-img-blk">
              <video
                          width="400"
                          autoPlay
                          loop
                          muted
                          className="img-fluid"
                        >
                          <source src={ModeVideo2} type="video/webm" />
                        </video>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/***************/}
      {/* Formas Section Ends */}
      {/* ***************/}
            {/***************/}
      {/* Connect Section Starts */}
      {/* ***************/}

    
      {/***************/}
      {/* Connect Section Ends */}
      {/* ***************/}
      {/* Team Starts */}
      <Team />
      {/* Team Ends */}
      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
      <div id="postscribe-script"></div>
    </div>
  );
}
