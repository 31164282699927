import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs, Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import PrevIcon from "../images/prev-icon.png";
import NextIcon from "../images/next-icon.png";
import Team1 from "../images/team-1.png";
import Team2 from "../images/team-2.png";
import Team4 from "../images/team-4.png";
import Team5 from "../images/team-5.png";
import Team6 from "../images/team-6.png";
import Team7 from "../images/team-7.png";
import Team8 from "../images/team-8.png";
import Team9 from "../images/team-9.png";

export default class Team extends React.Component {
  state = {
    whonavigationPrevRef: React.createRef(),
    whonavigationNextRef: React.createRef(),
  };
  render() {
    return (
      <>
        {/***************/}
        {/* Who Section Start */}
        {/* ***************/}
        <section className="who-section section-blur-effect" id="team">
          <div className="container-fluid container-lg">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 offset-lg-1 text-center">
                <h2 className="main-title">Our Team</h2>
                <p className="px-lg-5 mx-lg-5">
                We are a group of creative residents from different parts of the world, looking to create the best NFT shooter game.
                </p>
              </div>
              <div className="swiper-slider-nav">
                <div ref={this.state.whonavigationPrevRef}>
                  <img src={PrevIcon} alt="icon" className="img-fluid" />
                </div>
                <div ref={this.state.whonavigationNextRef}>
                  <img src={NextIcon} alt="icon" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="who-swiper-slider-blk">
                  <Swiper
                    modules={[Navigation]}
                    loop={true}
                    navigation={{
                      prevEl: this.state.whonavigationPrevRef.current,
                      nextEl: this.state.whonavigationNextRef.current,
                    }}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                      },
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      992: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                      1200: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                      },
                    }}
                    className="who-slider"
                  >
                    <SwiperSlide>
                      <div className="who-slider-blk">
                        <a href="https://www.linkedin.com/in/aquiles-benitez-658411128/" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team1} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Aquiles </h2>
                          <h4>CEO & Co-Founder</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="https://www.linkedin.com/in/yandun-wilson/"  rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team2} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Wilson </h2>
                          <h4>CTO & Co-Founder</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="https://www.linkedin.com/in/eduardo-soberanis-649159146/" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team5} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Eduardo </h2>
                          <h4>Game Artist</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="#" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team4} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Christian </h2>
                          <h4>3D Modeler</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="#" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team9} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Nathan  </h2>
                          <h4>Developer</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="#" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team7} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Cesar  </h2>
                          <h4>Illustrator</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="who-slider-blk">
                      <a href="https://www.linkedin.com/in/norberto3d/" rel="noreferrer"  target="_blank">
                        <div className="who-slider-img">
                          <img src={Team6} alt="" className="img-fluid" />
                        </div>
                        <div className="who-slider-text">
                          <h2>Norberto  </h2>
                          <h4>3D Generalist</h4>
                        </div>
                        </a>
                      </div>
                    </SwiperSlide>
                    

                    
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/***************/}
        {/* Who Section End */}
        {/* ***************/}
      </>
    );
  }
}
