import React from "react";
import PartnerIcon1 from "../images/partner-icon-1.png";
import PartnerIcon2 from "../images/partner-icon-2.png";
import PartnerIcon3 from "../images/partner-icon-3.png";
import Twitter from "../images/twitter.svg";
import YouTube from "../images/youtube.svg";
import Discord from "../images/discord.svg";
import Telegram from "../images/telegram.svg";
import GunnyLogo from "../images/gunny-logo.png";
import Gunicon from "../images/gun-icon.png";
import FooterWood from "../images/footer-wood.png";
import MailchimpFooter from "./mailchimpFooter";

export default class Footer extends React.Component {
  render() {
    return (
      <>
        {/***************/}
        {/* Footer Section Start */}
        {/* ***************/}
        <section className="footer-section-blk section-blur-effect">
          <div className="partner-section">
            <div className="container-fluid container-lg">
              <div className="row">
                <div className="col-12 col-lg-10 offset-lg-1 text-center">
                  <h2 className="main-title">Partners</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <ul className="list-inline partners-icons">
                    <li className="list-inline-item">
                      <a href="https://www.borderlesscapital.io/" target="_blank">
                        <img
                          src={PartnerIcon1}
                          alt="icons"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.algorand.foundation/" target="_blank">
                        <img
                          src={PartnerIcon2}
                          alt="icons"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://www.rokk3r.com/" target="_blank">
                        <img
                          src={PartnerIcon3}
                          alt="icons"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-top-section">
              <div className="container-fluid container-lg">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="footer-logo-blk">
                      <a href="#" className="footer-logo">
                        <img src={GunnyLogo} alt="logo" className="img-fluid" />
                      </a>
                    </div>
                    <div className="footer-social-blk">
     
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h2 className="footer-title">Quick Links</h2>
                    <ul className="footer-links list-unstyled">
                      <li>
                        <a href="#">
                          <img src={Gunicon} alt="icon" className="img-fluid" />
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#weapons">
                          <img src={Gunicon} alt="icon" className="img-fluid" />
                          Weapons
                        </a>
                      </li>
                      <li>
                        <a href="#champions">
                          <img src={Gunicon} alt="icon" className="img-fluid" />
                          Champions
                        </a>
                      </li>
                      <li>
                        <a href="#team">
                          <img src={Gunicon} alt="icon" className="img-fluid" />
                          Team
                        </a>
                      </li>
                      <li>
                        <a href="#pets">
                          <img src={Gunicon} alt="icon" className="img-fluid" />
                          Pets
                        </a>
                      </li>

                    </ul>
                  </div>
                  <div className="col-lg-5">
               
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright-section">
              <div className="container-fluid container-lg">
                <div className="row">
                  <div className="col-lg-6 text-center text-lg-left">
                    <ul className="list-inline copyright-link">
                      <li className="list-inline-item">
                        <a href="#">Terms of Use</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://nftgames.gitbook.io/gunny/">Whitepaper</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 text-center text-lg-right">
                    Copyright © 2022 Gunny Games.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={FooterWood} alt="footer" className="img-fluid w-100" />
        </section>
        {/***************/}
        {/* Footer Section Ends */}
        {/* ***************/}
      </>
    );
  }
}
